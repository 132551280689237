import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Recall from '../../components/servicos/ServicesPage/Recall'

function Page() {
  return (
    <>
      <GatsbySeo title="Recall | Decathlon" noindex nofollow />
      <Recall />
    </>
  )
}

export default Page
